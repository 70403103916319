/*Universal display settings for the feauters container*/
.nextensio__features {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 2rem 6rem;

    background: aliceblue;
    background-size: 100%;
    background-position: right;
    background-repeat: no-repeat;

    border: none;
    outline: none;
    padding: 2rem 2rem;
    border-radius: 2.25rem;
    filter: drop-shadow(0px 7px 7px rgba(0, 0, 0, 0.25));
}

/*DARK mode background modifications*/
#dark .nextensio__features {
    background-color: rgba(160, 210, 255, 0.15);
}

/*Display settings for the heading*/
.nextensio__features-heading{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-bottom: 0.25rem;
}

/*ROCKET Emoji*/
.nextensio__features h2{
    font-size: 26px;
}

/*Customizations for MAIN heading*/
.nextensio__features h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;

    margin-left: 0.3rem;
}

/*Customizations for SECONDARY subheading*/
.nextensio__features h3 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;

    color: var(--color-primary);
}

/*Customizations for the subtext hyperlink*/
.nextensio__features p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;

    color: var(--color-secondary);
    cursor: pointer;
    margin-top: 1rem;
}

/*Hover animation for the hyperlink*/
.nextensio__features p:hover {
    text-decoration: underline;
}

/*Widescreen formatting*/
@media screen and (min-width: 1920px) {
    .nextensio__features{
        margin: 2rem 20rem;
    }

    .nextensio__features h2{
        font-size: 34px;
        margin-bottom: 0.75rem;
    }

    .nextensio__features h1{
        font-size: 42px;
        margin-bottom: 0.75rem;
    }

    .nextensio__features h3{
        font-size: 24px;
        line-height: 50px;
    }

    .nextensio__features p{
        font-size: 22px;
    }
}

/*Computer formatting*/
@media screen and (min-width: 1480px) {
    .nextensio__features{
        padding: 3rem 3rem;
    }
    
    .nextensio__features h2 {
        font-size: 32px;
    }
    
    .nextensio__features h1 {
        font-size: 40px;
        line-height: 48px;
    }
    
    .nextensio__features h3{
        font-size: 24px;
        line-height: 40px;
    }

    .nextensio__features p{
        font-size: 22px;
    }

    .nextensio__features-heading{
        margin-bottom: 0.75rem;
    }
}

/*Computer formatting #2*/
@media screen and (max-width: 1050px) {
    .nextensio__features{
        margin: 2rem 4rem;
    }
}

/*Tablet formatting*/
@media screen and (max-width: 850px) {
    .nextensio__features{
        margin: 2rem 2rem;
    }
}

/*Mobile formatting*/
@media screen and (max-width: 490px) {
    .nextensio__features{
        margin: 2rem 2rem;
        padding: 1.25rem;
        border-radius: 1rem;

        background-size: 210%;
        background-position: center;
    }

    .nextensio__features h2{
        font-size: 22px;
    }

    .nextensio__features h1{
        font-size: 30px;
    }

    .nextensio__features h3{
        font-size: 20px;
        line-height: 32px;
    }

    .nextensio__features p{
        font-size: 16px;
        margin-top: 0.5rem;
    }
}