* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }

/*DARK mode master settings*/
#dark {
  background-color: #181818;
  color-scheme: dark;
}

/*DEFAULT BODY color*/
#light {
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 17% 10%, rgba(255, 255, 255, 1) 0%, rgba(230, 219, 230, 1) 100%);
    
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 17% 10%, rgba(255, 255, 255, 1) 0%, rgba(230, 219, 230, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 17% 10%, rgba(255, 255, 255, 1) 0%, rgba(230, 219, 230, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 17% 10%, rgba(255, 255, 255, 1) 0%, rgba(230, 219, 230, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 17% 10%, rgba(255, 255, 255, 1) 0%, rgba(230, 219, 230, 1) 100%);
}

a {
    color: unset;
    text-decoration: none;
}

/*Universal gradient text customization variable*/
.gradient__text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*Universal sizing for computer devices*/
.section__padding {
    padding: 4rem 6rem;
}

/*Universal sizing for computer devices*/
.section__margin {
    margin: 4rem 6rem;
}

/*Gradient bar customizations*/
.app__gradient-bar div{
  width: 100%;
  height: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  background: var(--gradient-bar);
}

/*Entire Scrollbar customizations*/
::-webkit-scrollbar {
  width: 16px;
}

/*Scrollbar track customizations*/
::-webkit-scrollbar-track {
  background-color: whitesmoke;
}

/*Scrollbar draggable track*/
::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  border-radius: 16px;
  background-color: #babac0;
  background-clip: content-box;
}

/*Hover animation for the scrollbar*/
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(180deg, #1d70be 1.84%, #00b189 102.67%);
}

/*slide-in-right animation*/
.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/*slide-out animation*/
.slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}

/*fade-in animation*/
.fade-in {
	-webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*Media Queries => website adaptability to different device's px sizes*/
/*Universal widescreen fomratting*/
@media screen and (min-width: 1920px){
  .section__padding{
    padding: 4rem 20rem;
  }
}

/*Universal tablet fomratting*/
@media screen and (max-width: 700px){
  .section__padding{
    padding: 4rem;
  }

  .section__margin{
    margin: 4rem;
  }
}

/*Universal mobile fomratting*/
@media screen and (max-width: 550px){
  .section__padding{
    padding: 2rem 2rem;
  }

  .section__margin{
    margin: 2rem 2rem;
  }
}