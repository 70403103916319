/*Universal display settings for CTA component*/
.nextensio__cta {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    /*Top, Right, Bottom, Left*/
    padding: 4rem 15rem 4rem 3rem;
    margin: 3rem 4rem 6rem 4rem;

    border: none;
    outline: none;
    background: rgb(255, 220, 220);
    border-radius: 2.25rem;
    filter: drop-shadow(0px 7px 7px rgba(0, 0, 0, 0.25));
}

/*DARK mode background modifications*/
#dark .nextensio__cta {
    background: rgba(255, 160, 160, 0.4);
}

/*Display settings for CTA text*/
.nextensio__cta-content {
    display: block;
}

.nextensio__cta-content-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

/*Customization for Heading text*/
.nextensio__cta-content h2 {
    font-family: var(--font-family);
    color: var(--color-primary);
    font-weight: 500;
    font-size: 40px;
    line-height: 46px;
}

/*Customization for Heading text => Nextensio Gradient*/
.nextensio__cta-content h2:last-child {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 46px;
    margin-left: 10px;
}

/*Customization for Subheading text*/
.nextensio__cta-content p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;

    color: var(--color-primary);
    width:fit-content;
    margin-top: 0.75rem;
    margin-bottom: 1.75rem;
}

/*Display settings for button*/
.nextensio__cta-interact {
    display: flex;
    align-items: center;
    flex-direction: row;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
}

/*Customization for Email Input*/
.nextensio__cta-interact input{
    /*FLEX is used to determine the length of the input bar!*/
    width: 40rem;
    min-height: 54px;
    
    background: #FFFFFF;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 0.75rem 1rem;
    border: none;
    outline: none;

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: black;
}

/*Customization for button*/
.nextensio__cta-interact button{
    background: black;
    border: none;
    outline: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    min-width: 150px;
    padding: 0.75rem 1rem;
    
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;

    cursor: pointer
}

/*Hover color-change animation for button*/
.nextensio__cta-interact button:hover{
    background-color: #2f2f2f;
}

/*Widescreen formatting*/
@media screen and (min-width: 1920px){
    .nextensio__cta{
        margin: 3rem 20rem 6rem 20rem;
    }

    .nextensio__cta-content h2 {
        font-size: 45px;
        line-height: 45px;
        width: max-content;
    }
    
    .nextensio__cta-content h2:last-child {
        font-size: 45px;
        line-height: 45px;
    }

    .nextensio__cta-content p {
        font-size: 22px;
        line-height: 36px;
    }
}

/*Computer formatting*/
@media screen and (min-width: 1480px) {
    .nextensio__cta-content h2,
    .nextensio__cta-content h2:last-child{
        font-size: 50px;
        margin-bottom: 1rem;
    }
    
    .nextensio__cta-content p{
        font-size: 24px;
        line-height: 38px;
    }

    .nextensio__cta-interact input{
        font-size: 22px;
        line-height: 32px;
    }

    .nextensio__cta-interact button{
        font-size: 20px;
        line-height: 32px;
    }
}

/*Large Tablet formatting*/
@media screen and (max-width: 1000px) {
    .nextensio__cta-content-title h2 {
        width: max-content;
    }

    .nextensio__cta-interact input{
        width: 30rem;
    }
}

/*Tablet formatting*/
@media screen and (max-width: 850px) {
    .nextensio__cta {
        flex-direction: column;
        margin: 3rem 2rem 6rem 2rem;
        padding: 4rem 8rem 4rem 3rem;
    }

    .nextensio__cta-interact {
        margin: 0.5rem 0 0;
    }

    .nextensio__cta-interact input{
        width: 25rem;
    }
}

/*Mobile formatting*/
@media screen and (max-width: 700px) {
    .nextensio__cta {
        margin: 2rem 2rem 4rem 2rem;
        padding: 2rem;
        display: block;
    }

    .nextensio__cta-content-title{
        justify-content: center;
    }

    .nextensio__cta-content h2 {
        font-size: 34px;
        line-height: 33px;
        margin-bottom: 0.25rem;
        text-align: center;
    }

    .nextensio__cta-content h2:last-child {
        font-size: 34px;
        text-align: center;
    }

    .nextensio__cta-content p {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .nextensio__cta-interact {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .nextensio__cta-interact input{
        justify-content: space-between;
        width: fit-content;
        border-radius: 10px;
        text-align: center;
        padding: 0rem 7rem;
        min-height: 40px;
        margin-bottom: 1.5rem;
    }

    .nextensio__cta-interact button {
        font-size: 18px;
        min-width: 0;
        padding: 0.4rem 1.5rem;
        line-height: 28px;
        border-radius: 20px;
    }
}

/*Mobile formatting #2*/
@media screen and (max-width: 561px) {
    .nextensio__cta {
        border-radius: 20px;
    }

    .nextensio__cta-content-title {
        flex-direction: column;
        justify-content: center;
    }

    .nextensio__cta-content h2,
    .nextensio__cta-content h2:last-child {
        font-size: 30px;
        line-height: 33px;
    }

    .nextensio__cta-interact input{
        padding: 0rem 4rem;
    }
}

/*Mobile formatting #3*/
@media screen and (max-width: 460px) {
    .nextensio__cta-interact input{
        padding: 0rem 2rem;
    }
}