/*Display settings for text content container*/
.nextensio__TextTemplate-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

/*Customizations for h1 text*/
.nextensio__TextTemplate-container__title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;

    margin: 1.1rem 0;
}

/*Customizations for p text*/
.nextensio__TextTemplate-container__text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    
    color: var(--color-primary);
}

/*Customizations for h4 text*/
.nextensio__TextTemplate-container__text h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;

    margin-top: 1rem;
    color: var(--color-secondary);
    cursor: pointer;
}

/*Hover animation for linked text*/
.nextensio__TextTemplate-container__text h4:hover {
    text-decoration: underline;
}

/*Widescreen formatting*/
@media screen and (min-width: 1920px){
    .nextensio__TextTemplate-container__title h1{
        font-size: 50px;
        line-height: 58px;

        width: fit-content;
    }
    
    .nextensio__TextTemplate-container__text p {
        font-size: 26px;
        line-height: 40px;
        
        width: fit-content;
    }

    .nextensio__TextTemplate-container__text h4{
        font-size: 26px;
        line-height: 40px;
    }
}

/*Computer formatting*/
@media screen and (min-width: 1480px) {
    .nextensio__TextTemplate-container__title h1{
        font-size: 44px;
        line-height: 56px;
    }

    .nextensio__TextTemplate-container__text p{
        font-size: 26px;
        line-height: 40px;
    }

    .nextensio__TextTemplate-container__text h4{
        font-size: 24px;
        line-height: 36px;
    }
}

/*Tablet formatting*/
@media screen and (max-width: 950px) {
    .nextensio__TextTemplate-container {
        margin-top: 2rem;
    }

    .nextensio__TextTemplate-container__title h1{
        font-size: 32px;
        line-height: 40px;
        margin: unset;
        margin-bottom: 0.6rem;
    }

    .nextensio__TextTemplate-container__text p{
        font-size: 20px;
        line-height: 32px;
    }

    .nextensio__TextTemplate-container__text h4{
        font-size: 20px;
        line-height: 32px;
    }
}