/*Universal display settings for the possibility container*/
.nextensio__possibility {
    padding: 4rem 4rem;
}

/*Display setings for container w/text on the rightside*/
.nextensio__possibility_rightText {
    display: flex;
    flex-direction: row;
    margin-bottom: 8rem;
}

/*Display setings for last container*/
.nextensio__possibility_rightText:nth-last-child(2) {
    margin-bottom: unset;
}

/*Display setings for container w/text on the leftside*/
.nextensio__possibility_leftText {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 8rem;
}

/*Display settings for the image*/
.nextensio__possibility-image {
    flex: 0.8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/*Template display settings for the images*/
.nextensio__possibility-leftImage,
.nextensio__possibility-rightImage {
    flex: 0.8;
    display: flex;
    align-items: center;
}

/*Display positioning for leftImage*/
.nextensio__possibility-leftImage {
    justify-content: left;
}

/*Display positioning for rightImage*/
.nextensio__possibility-rightImage {
    justify-content: right;
}

/*Customizations for image size*/
.nextensio__possibility-leftImage img,
.nextensio__possibility-rightImage img{
    width: 85%;
}

/*Display settings for text content container*/
.nextensio__possibility-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

/*Display settings for bulletpoints*/
.nextensio__possibility-list {
    width:100%;
    flex-direction: column;
    margin-bottom: 3rem;
}

/*Widescreen formatting*/
@media screen and (min-width: 1920px){
    .nextensio__possibility {
        padding: 4rem 20rem 4rem 20rem;
    }
}

/*Computer formatting*/
@media screen and (min-width: 1480px) {
    .nextensio__possibility-leftImage,
    .nextensio__possibility-rightImage {
        justify-content: center;
    }
    
    .nextensio__possibility-leftImage img,
    .nextensio__possibility-rightImage img {
        width: 75%;
    }
}

/*Tablet formatting*/
@media screen and (max-width: 950px) {
    .nextensio__possibility {
        flex-direction: row;
        padding: 2rem 2rem;
    }

    .nextensio__possibility_leftText,
    .nextensio__possibility_rightText{
        margin-bottom: 4rem;
    }

    .nextensio__possibility-leftImage,
    .nextensio__possibility-rightImage {
        justify-content: center;
    }

    .nextensio__possibility-list {
        margin-bottom: unset;
    }
}

/*Tablet formatting #2*/
@media screen and (max-width: 700px) {
    .nextensio__possibility_leftText,
    .nextensio__possibility_rightText{
        flex-direction: column;
        justify-content: center;
    }

    .nextensio__possibility-leftImage img,
    .nextensio__possibility-rightImage img {
        width: 70%;
    }
}

/*Mobile formatting*/
@media screen and (max-width: 490px) {
    .nextensio__possibility {
        padding: 4rem 2rem 0rem 2rem;
    }
    
    .nextensio__possibility-leftImage,
    .nextensio__possibility-rightImage {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .nextensio__possibility-leftImage img,
    .nextensio__possibility-rightImage img {
        width: 75%;
    }

    .nextensio__possibility-content{
        margin-bottom: 2rem;
    }

    .nextensio__possibility-content:last-child{
        margin: none;
    }

    .nextensio__possibility-list {
        margin-top: unset;
    }
}