@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

:root {
  /*Universal font and color settings for the entire website*/
  --font-family: 'Quicksand', sans-serif;

  /*Gradients*/
  --gradient-text: linear-gradient(89.97deg, #1d70be 1.84%, #00b189 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #1d70be -13.86%, #00b189 99.55%);
  
  /*Palette Colors*/
  --color-primary: black;
  --color-secondary: rgb(129, 175, 221);
  --color-tertiary: #1d70beee;
  --color-icon: #999999;
  
  /*Header + Footer*/
  --color-ribbon: white;
  --color-footer-text: black;
}

/*DARK MODE Palette Settings*/
#dark {
  /*Palette Colors*/
  --color-primary: rgba(255, 255, 255, 0.85);
  --color-secondary: rgba(129, 175, 221, 0.90);
  --color-tertiary: #1d70be;
  --color-icon: #BFBFBF;

  /*Header + Footer*/
  --color-ribbon: #242526;
  --color-footer-text: #BFBFBF;
}