/*This CSS file describes the formatting and positioning of theheader*/
/*Universal display settings for ALL header features*/
.nextensio__header {
    display: flex;
    padding: 2rem 6rem 6rem 6rem; 
}

/*Universal display settings for the header texts*/
.nextensio__header-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

/*Header display settings*/
.nextensio__header-content h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -0.04rem;

}

/*TYPEWRITER ANIMATION*/
/*Subtext display settings*/
.nextensio__header-content-animation p{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;

    color: var(--color-secondary);
    margin-top: 1.75rem;

    /*TYPEWRITER EFFECT*/
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid var(--color-secondary); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    animation-duration: 10s;
    animation:
      typing 12s steps(40),
      blink-caret 0.75s step-end infinite;
    animation-iteration-count: infinite;
  }

  /* The typing effect */
  @keyframes typing {
    0% { width: 0% }
    40% { width: 100% }
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--color-secondary); }
  }

/*Email user-entry display settings*/
.nextensio__header-content__input {
    display: flex;
    width: 100%;
    margin: 1.5rem 0rem 1rem;
}

/*"Get Out White Paper" button display settings*/
.nextensio__header-content__input button {
    flex-direction: row;
    padding: 0.6rem 1.5rem;
    outline: none;
    border: none;
    
    background: var(--gradient-text);
    border-radius: 25px 25px 25px 25px;

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.04em;
    color: #FFFFFF;

    cursor: pointer;
}

/*Hover transform animation for button*/
.nextensio__header-content__input button:hover {
    transform: scale(0.99);
    transition-duration: 0.1s;
}

/*"Learn More" button display settings*/
.nextensio__header-content__input-explore button{
    display: flex;
    margin-left: 2rem;
    border: 1px solid;
    outline: none;
    font-weight: 500;
    background: transparent;
    color: var(--color-primary);
}

/*Hover color-change animation for button*/
.nextensio__header-content__input-explore button:hover{
    background-color: var(--color-secondary);
    color: white;
}

/*DARK mode modifications for the button animation*/
#dark .nextensio__header-content__input-explore button:hover{
    border: 1px solid #161616;
}

/*Header Image display settings*/
.nextensio__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Header Image size settings*/
.nextensio__header-image img{
    width: 90%;
}

/*Widescreen formatting*/
@media screen and (min-width: 1920px){
    .nextensio__header {
        padding: 6rem 20rem 14rem 20rem;
    }

    .nextensio__header-content h1{
        font-size: 70px;
        line-height: 80px;
        margin-bottom: 2rem;
    }

    .nextensio__header-content p{
        margin: unset;
        margin-bottom: 1rem;
    }

    .nextensio__header-image img{
        width: 100%;
    }
}

/*Computer formatting*/
@media screen and (min-width: 1480px) {
    .nextensio__header-content h1{
        font-size: 64px;
        line-height: 78px;
    }

    .nextensio__header-content p{
        font-size: 22px;
        line-height: 27px;
    }

    .nextensio__header-content__input button{
        font-size: 22px;
    }
}

/*Computer formatting*/
@media screen and (max-width: 1050px){
    .nextensio__header {
        flex-direction: column;
    }

    .nextensio__header-image img {
        width: 65%;
    }

    .nextensio__header-content{
        margin: 0 0 3rem;
    }

    .nextensio__header-content__input button,
    .nextensio__header-content__input input{
        font-size: 20px;
        line-height: 27px;
        flex-direction: row;
    }
}

/*Tablet formatting*/
@media screen and (max-width: 850px){
    .nextensio__header {
        padding: 4rem 6rem 6rem;
    }

    .nextensio__header h1{
        font-size: 48px;
        line-height: 60px;
    }

    .nextensio__header p{
        font-size: 16px;
        line-height: 24px;
    }

    .nextensio__header-image img {
        width: 75%;
    }

    .nextensio__header-content__input button,
    .nextensio__header-content__input input{
        font-size: 16px;
        line-height: 24px;
        flex-direction: row;
    }
}

/*Tablet formatting #2*/
@media screen and (max-width: 700px){
    .nextensio__header{
        padding: 2rem 3rem 3rem;
    }
}

/*Mobile formatting*/
@media screen and (max-width: 490px){
    .nextensio__header{
        padding: 1rem 2rem 4rem;
    }

    .nextensio__header h1{
        font-size: 40px;
        line-height: 48px;
    }

    .nextensio__header p{
        font-size: 14px;
        line-height: 24px;

        margin-top:0.75rem;
    }

    .nextensio__header-image img {
        width: 85%;
    }

    .nextensio__header-content__input button {
        border-radius: 15px;
        padding: 0.6rem 0.75rem;
        
        font-size: 16px;
        line-height: 16px;
        flex-direction: row;
    }

    .nextensio__header-content__input-explore button {
        margin-left: 1rem;
    }
}