/*Universal settings for the feature BOXES*/
.nextensio__features-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

/*Main container display settings*/
.nextensio__features-container:first-child {
    margin: unset;
}

/*Gradient bar settings for each feature box*/
.nextensio__features-container__feature-title div{
    width: 50px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 3.5px 3.5px rgba(0, 0, 0, 0.10);
    margin-bottom: 0.8rem;
}

/*Title customizations for each features box*/
.nextensio__features-container__feature-title h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    
    letter-spacing: -0.02rem;
    color: var(--color-secondary);
}

/*Paragraph display settings for each features box*/
.nextensio__features-container__feature-text {
    flex: 2;
    max-width: 390px;
    display: flex;
}

/*Paragraph customizations for each features box*/
.nextensio__features-container__feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    color: var(--color-primary);
}

/*Computer formatting*/
@media screen and (min-width: 1480px) {
    .nextensio__features-container__feature-title div{
        width: 75px;
    }

    .nextensio__features-container__feature-title h1 {
        font-size: 36px;
        line-height: 50px;
    }

    .nextensio__features-container__feature-text p {
        font-size: 22px;
        line-height: 32px;
        min-width: 400px;
    }
}

/*Tablet formatting*/
@media screen and (max-width: 950px) {
    .nextensio__features-container {
        align-items: flex-start;
    }

    .nextensio__features-container__feature-title h1 {
        flex-direction: row;
        width: max-content;
        align-items: center;
        width: 100%;

        font-size: 24px;
        line-height: 24px;
    }

    .nextensio__features-container__feature-text p{
        font-size: 18px;
        line-height: 24px;
    }
}

/*Mobile formatting*/
@media screen and (max-width: 560px) {
    .nextensio__features-container__feature-title div{
        display: inline-block;
        width: 85px;
    }

    .nextensio__features-container__feature-title h1 {
        font-size: 30px;
        margin-bottom: 0.75rem;
    }

    .nextensio__features-container__feature-text p{
        font-size: 20px;
        line-height: 28px;

        margin-bottom: 2rem;
    }
}