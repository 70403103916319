/*Universal display settings for ALL Banner features*/
.nextensio__banner {
    display: none;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 0.75rem 1.5rem;
    background: rgb(0, 177, 137);
}

/*DARK mode background modifications*/
#dark .nextensio__banner {
    background: rgba(29, 112, 190, 0.4);
}

/*Display settings for the banner texts */
.nextensio__banner-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

/*Customization for the banner emojis*/
.nextensio__banner h5{
    align-items: center;
    font-size: 18px;
    margin: 0rem 0.375rem;
}

/*Customization for the unlinked banner texts*/
.nextensio__banner-text p{
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: var(--color-primary);
}

/*Display settings for the hyperlink text*/
.nextensio__banner a{
    text-align: center;
    margin: 0rem 0.375rem;
}

/*Customization for the hyperlink text*/
.nextensio__banner h4{
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 500;
    text-decoration: underline;
    color: var(--color-primary);
}

/*Display settings for the CLOSE ICON*/
.nextensio__banner-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 107.17px;
}

/*Display Settings for the Toggler Text ==> HIDDEN*/
.nextensio__banner-toggle svg{
    color: var(--color-icon);
    cursor: pointer;
}

/*Hover animations for the banner*/
.nextensio__banner-text h4:hover,
.nextensio__banner-toggle svg:hover{
    color: var(--color-secondary);
}

/*Computer formatting*/
@media screen and (min-width: 1055px){
    .nextensio__banner {
        display: flex;
    }
}