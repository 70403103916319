/*Universal display settings for the individual article container*/
.nextensio__blog-container_article {
   width: 100%;
   height: 100%;
   
   display: flex;
   flex-direction: column;
   background: white;
   border-radius: 20px;
   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

   cursor: pointer;
}

/*DARK mode settings*/
#dark .nextensio__blog-container_article {
    background: #080808;
}

/*Hover transform animation for all blog containers*/
.nextensio__blog-container_article:hover {
    transform: scale(1.015);
    transition-duration: 0.5s;
    filter: drop-shadow(0px 7px 7px rgba(0, 0, 0, 0.25));
}

/*Display settings for the blog images*/
.nextensio__blog-container_article-image {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;

   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
   background: rgb(255, 220, 220);
}

/*DARK mode settings*/
#dark .nextensio__blog-container_article-image {
    background: rgba(255, 170, 170, 0.4);
}

/*Customizations for the blog images*/
.nextensio__blog-container_article-image img{
   width: 100%;
   height: 100%;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
   border: none;
   outline: none;
}

/*Display settings for the blog text*/
.nextensio__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    padding: 1rem 1.5rem;
    height: 100%;
}

/*Customizations for the CATEGORY text*/
.nextensio__blog-container_article-content h2{
    font-family: var(--font-family);
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    line-height: 35px;
    width: max-content;
}

/*Customizations for the TITLE text*/
.nextensio__blog-container_article-content h3{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;

    color: var(--color-primary);
    margin-bottom: 1rem;
}

/*Customizations for the DATE text*/
.nextensio__blog-container_article-content p{
    font-family: var(--font-family);
    text-transform: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 34px;

    color: var(--color-secondary);
}

/*Computer formatting*/
@media screen and (min-width: 1480px) {
    .nextensio__blog-container_article-content h2{
        font-size: 16px;
        line-height: 38px;
    }
    
    .nextensio__blog-container_article-content h3{
        font-size: 24px;
        line-height: 34px;
    }

    .nextensio__blog-container_article-content p{
        font-size: 18px;
        line-height: 38px;
    }
}

/*Mobile formatting*/
@media screen and (max-width: 490px) {
    .nextensio__blog-container_article-content h2{
        font-size: 16px;
        line-height: 40px;
    }
}