/*Universal display settings the entire features component*/
.nextensio__whatNextensio {
    display: flex;
    flex-direction: column;
}

/*Display setting for "What is Nextensio*/
.nextensio__whatNextensio-feature {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
    border-radius: 25px 25px 25px 25px;
    background: #ffffff;

    /*4-side shadow vs. 2-side shadow (box-shadow)*/
    filter: drop-shadow(0px 7px 7px rgb(0,0,0, 0.25));
}

/*DARK mode settings*/
#dark .nextensio__whatNextensio-feature {
    background: #3A3B3C;
}

/*Display settings for Gradient Bar + "What is Nextensio"*/
.nextensio__whatNextensio-features-title-block{
    display: block;
    flex-direction: row;
}

/*Customization for gradient bar*/
.nextensio__whatNextensio-features-title-block div{
    width: 50px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.5rem;
}

/*Customizations for "What is Nextensio" header*/
.nextensio__whatNextensio-features-title-block h2{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
    width: 200px;
    
    letter-spacing: -0.02rem;
    color: var(--color-secondary);
}

/*Customizations for "What is Nextensio" subtext*/
.nextensio__whatNextensio-feature p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;

    color: var(--color-primary);
}

/*Display setting for header*/
.nextensio__whatNextensio-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    margin: 5rem 0 3rem;
}

/*Header => IMAGE customizations*/
.nextensio__whatNextensio-heading img{
    display: flex;
    width: 50%;

    justify-content: flex-start;
}

/*Header => Gradient TEXT customizations*/
.nextensio__whatNextensio-heading h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
    max-width: 510px;
    
    text-align: center;
    justify-content: center;
    align-items: center;
}

/*Display settings for "Request Demo" button*/
.nextensio__whatNextensio-heading__interact-input {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 2rem 0rem 0rem 0rem;
}

/*Customizations for "Request Demo" button*/
.nextensio__whatNextensio-heading__interact-input button{
    border: 1px solid;
    background-color: transparent;
    border-radius: 25px 25px 25px 25px;
    padding: 0.5rem 1rem;

    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    max-width: 510px;

    cursor: pointer;
    color: var(--color-primary);
}

/*Hover color-change animation for button*/
.nextensio__whatNextensio-heading__interact-input button:hover{
    background-color: var(--color-secondary);
    color: white;
}

/*DARK mode modifications for the button animation*/
#dark .nextensio__whatNextensio-heading__interact-input button:hover{
    border: 1px solid #161616;
}

/*Display seeting for the three feature boxes*/
.nextensio__whatNextensio-container {
    display: flex;
    justify-content: space-around;
    flex-direction: row;

    margin-top: 2rem;
}

/*Display spacing for the three containers*/
.nextensio__whatNextensio-container-spacer {
    display: flex;
}

/*Icon customizations*/
.nextensio__whatNextensio-container svg{
    color: var(--color-tertiary);
    margin-left: -0.1rem;
    margin-bottom: 0.3rem;
}

/*Display setting for the THREE containers ==> SPACING*/
.nextensio__whatNextensio-container__icons:first-child{
    margin: 0 3rem 0 0;
}

/*Display setting for the THREE containers ==> SPACING*/
.nextensio__whatNextensio-container__icons:last-child{
    margin: 0 0 0 3rem;
}

/*Display settings for the feature container*/
.nextensio__whatNextensio-container
.nextensio__features-container {
    min-width: 210px;
    display: unset;
    flex-direction: row;
}

/*Display settings for the feature texts*/
.nextensio__whatNextensio-container
.nextensio__features-container__feature-text {
    margin-top: 0.3rem;
}

/*ULTRA-Widescreen formatting*/
@media screen and (min-width: 2250px) {
    .nextensio__whatNextensio-feature{
        justify-content: space-between;
    }
    
    .nextensio__whatNextensio-container__icons:first-child{
        margin: 0 10rem 0 0;
    }

    .nextensio__whatNextensio-container__icons:last-child{
        margin: 0 0 0 10rem;
    }
}

/*Widescreen formatting*/
@media screen and (min-width: 1920px) {
    .nextensio__whatNextensio-features-title-block h2{
        font-size: 32px;
        line-height: 40px;
        width: 200px;
        margin-right: 1rem;
    }
    
    .nextensio__whatNextensio-heading img{
        margin-right: 3rem;
    }

    .nextensio__whatNextensio-heading__interact-input button{
        font-size: 24px;
        margin-top: 1.5rem;
    }

    .nextensio__whatNextensio-container svg{
        transform: scale(1.2);
        margin-bottom: 1rem;
    }
}

/*Computer formatting*/
@media screen and (min-width: 1480px) {
    .nextensio__whatNextensio-feature {
        padding: 2rem 3rem;
    }

    .nextensio__whatNextensio-features-title-block h2{
        font-size: 28px;
        line-height: 40px;
    }
    
    .nextensio__whatNextensio-feature p{
        font-size: 24px;
        line-height: 38px;
    }
    
    .nextensio__whatNextensio-heading h1 {
        font-size: 50px;
        line-height: 66px;
        margin-right: unset;
    }

    .nextensio__whatNextensio-heading__interact-input button {
        font-size: 22px;
    }
}

/*Computer formatting*/
@media screen and (max-width: 1050px) {
    .nextensio__whatNextensio {
        padding: 4rem 4rem;
    }
    
    .nextensio__whatNextensio-heading {
        flex-direction: row;
        margin-bottom: 3rem;
    }

    .nextensio__whatNextensio-heading p {
        margin-top: 1rem;
    }
}

/*Tablet formatting*/
@media screen and (max-width: 850px) {
    .nextensio__whatNextensio{
        padding: 4rem 2rem;
    }

    .nextensio__whatNextensio-features-title-block h2{
        width: 170px;
    }

    .nextensio__whatNextensio-heading{
        margin: 4rem 0 2rem;
    }

    .nextensio__whatNextensio-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

/*Tablet formatting #2*/
@media screen and (max-width: 800px){
    .nextensio__whatNextensio-feature {
        display: block;
        flex-direction: column;
    }

    .nextensio__whatNextensio-feature h2{
        width: max-content;
        margin-bottom: 0.5rem;
    }
    
    .nextensio__whatNextensio-feature {
        padding: 30px;
    }
}

/*Mobile formatting*/
@media screen and (max-width: 560px) {
    .nextensio__whatNextensio {
        padding: 4rem 2rem 0rem 2rem;
    }

    .nextensio__whatNextensio-feature {
        border-radius: 20px;
    }
    
    .nextensio__whatNextensio-container-spacer {
        display: block;
        justify-content: center;
    }

    .nextensio__whatNextensio-container__icons:first-child,
    .nextensio__whatNextensio-container__icons:last-child{
        margin: unset;
    }

    .nextensio__whatNextensio-container__icons {
        text-align: center;
    }

    .nextensio__whatNextensio-container__icons svg {
        width: 100%;
    }

    .nextensio__whatNextensio-heading {
        display: block;
        justify-content: center;
    }
    
    .nextensio__whatNextensio-heading img{
        width: 100%;
        justify-content: center;
        margin: unset;
    }

    .nextensio__whatNextensio-heading h1{
        margin: unset;
        margin-top: 0.75rem;
        margin-bottom: 1.5rem;
        text-align: center;

        font-size: 36px;
        line-height: 44px;
    }

    .nextensio__whatNextensio-heading__interact-input{
        margin-right: unset;
        justify-content: center;
    }
}