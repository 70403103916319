/*Universal display settings for the footer container*/
.nextensio__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 4rem 2rem 4rem;

    background-color: var(--color-ribbon);
}

/*Display setting for all footer links*/
.nextensio__footer-links {
    display: flex;
    justify-content:space-between;
    align-items: flex-start;
    flex-direction: row;

    width: 100%;
    text-align: left;
}

/*Display setting for each footer block*/
.nextensio__footer-links div{
    width: 170px;
}

/*Display setting for logo placement*/
.nextensio__footer-links_logo {
    display: flex;
    flex-direction: column;
    
    margin: 1rem 6rem 1rem 1rem;
}

/*Customizations for logo*/
.nextensio__footer-links_logo img{
    width: 120%;
    margin-bottom: 1rem;

    cursor: pointer;
}

/*Customizations for the ltext below the logo*/
.nextensio__footer-links_logo p{
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    width: max-content;

    color: var(--color-footer-text);
}

/*Customizations for the email hyperlink*/
.nextensio__footer-links_logo h6{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-decoration: underline;
    width: max-content;

    color: var(--color-footer-text);
    cursor: pointer;
}

/*Display settings for the social media icons*/
.nextensio__footer-links_social-media {
    display: flex;
    flex-direction: row;
}

/*Customizations for the social media icons*/
.nextensio__footer-links_social-media a{
    margin: 0.8rem 0.3rem 0rem 0.3rem;
    color: var(--color-footer-text);
}

/*Display customizations for the first icon spacing - to negate initial indent*/
.nextensio__footer-links_social-media a:first-child{
    margin-left: unset;
}

/*Display settings for EACH footer container*/
.nextensio__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    margin: 1rem;
}

/*Customization for EACH footer title*/
.nextensio__footer-links_div h4 {
    margin-bottom: 0.5rem;
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 20px;

    color: var(--color-secondary);
}

/*Customizations for each footer text links*/
.nextensio__footer-links_div p{
    margin: 0.5rem 0;
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 22px;
    width: fit-content;

    color: var(--color-footer-text);
    cursor: pointer;
}

/*Hover animation for footer links*/
.nextensio__footer-links_logo h6:hover,
.nextensio__footer-links_social-media svg:hover,
.nextensio__footer-links_div p:hover,
.nextensio__footer-links_hyperlinks svg:hover {
    text-decoration: underline;
    color: var(--color-secondary);
}

/*Display settings for company social media links*/
.nextensio__footer-links_hyperlinks a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    color: var(--color-footer-text);
}

/*Customizations for the social media text*/
.nextensio__footer-links_hyperlinks p {
    margin-left: 0.4rem;
}

/*Display settings + customizations for the gradient bar*/
.nextensio__footer_gradient-bar{
    justify-content: center;
    width: 100%;
    height: 2px;
    margin: 1rem 0rem 1rem 0rem;
    background: rgb(230, 230, 230);
    margin: 3rem 0 1rem 0rem;
}

/*DARK mode for the gradient bar*/
#dark .nextensio__footer_gradient-bar{
    background: #BFBFBF;
}

/*Display setting for copyright*/
.nextensio__footer-copyright {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

/*Customizations for the copyright texts*/
.nextensio__footer-copyright p{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    margin-left: 1.1rem;

    color: var(--color-primary);
}

/*Customizations for the dropdown menu*/
.nextensio__footer-copyright select{
    display: inline-block;
    margin-right: 0.5rem;
    border-radius: 7px;
    padding: 5px;
    text-align: left;
    border: none;
    outline: none;

    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    background-color: var(--color-ribbon);
    color: var(--color-primary);
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/*Hover animation for the dropdown menu*/
.nextensio__footer-copyright select:hover{
    text-decoration: underline;
    color: var(--color-secondary);
}

/*Customization for the dropdown menu options*/
.nextensio__footer-copyright option{
    color: var(--color-primary);
}

/*Widescreen formatting*/
@media screen and (min-width: 1920px){
    .nextensio__footer{
        padding: 4rem 20rem 2rem 20rem;
    }
}

/*Mobile formatting*/
@media screen and (max-width: 1000px){
    .nextensio__footer-links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0rem 8rem;
    }

    .nextensio__footer-links div{
        width: fit-content;
    }
    
    .nextensio__footer-links_logo img{
         width: 110%;
    }
}

/*Mobile formatting #2*/
@media screen and (max-width:900px){
    .nextensio__footer-links{
        grid-gap: 0rem 10rem;
    }
}

/*Mobile formatting*/
@media screen and (max-width:750px){
    .nextensio__footer-links{
        grid-gap: 0rem 6rem;
    }
    
    .nextensio__footer-links_hyperlinks{
        height: fit-content;
    }

    .nextensio__footer-links div{
        margin: 1rem 0;
    }

    .nextensio__footer-copyright{
        display: block;
        text-align: center;
    }

    .nextensio__footer-copyright p{
        margin-left: unset;
        margin-bottom: 0.5rem;
    }
    .nextensio__footer-copyright select{
        margin-right: unset;
    }

}

/*Mobile formatting*/
@media screen and (max-width:550px){
    .nextensio__footer {
        padding: 2rem 2.5rem;
    }
    .nextensio__footer-links{
        grid-gap: 0rem 6rem;
    }

    .nextensio__footer-links_logo img{
        width: 105%
    }
}

/*Mobile formatting #2*/
@media screen and (max-width:490px){
    .nextensio__footer-links{
        grid-gap: 0rem 4rem;
    }
}