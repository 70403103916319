/*Universal display settings for the bulletpoint container*/
.nextensio__bulletpoints-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:flex-start;

    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*Customizations for the bulletpoint ICON*/
.nextensio__bulletpoints-container svg {
    color: var(--color-secondary);
}

/*Customization for the bulletpoint TEXT*/
.nextensio__bulletpoints-container h3 {
    font-family: var(--font-family);
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;

    margin-left: 0.5rem;
    color: var(--color-secondary);
}


/*Widescreen formatting*/
@media screen and (min-width: 1920px){
    .nextensio__bulletpoints-container {
        align-items: center;
    }
}

/*Computer formatting*/
@media screen and (min-width: 1480px) {
    .nextensio__bulletpoints-container h3{
        font-size: 26px;
        line-height: 36px;
    }
}

/*Tablet formatting*/
@media screen and (max-width: 950px) {
    .nextensio__bulletpoints-container h3 {
        line-height: 26px;
    }
    
    .nextensio__bulletpoints-container h3 {
        font-size: 20px;
        line-height: 32px;
        margin-left: 0.25rem;
    }
}


