/*This CSS file describes the formatting and positioning of the navBar*/
/*Universal display settings for ALL NavBar features*/
.nextensio__navbar {
    display: flex;
    align-items: center;
    z-index: 5;
    padding: 1rem 4rem;
    background-color: var(--color-ribbon);

    position: sticky;/*navbar maintain positions when the user scrolls down*/
    top: -0.01px;
}

/*Universal display settings for the logo placment*/
.nextensio__navbar-links_logo{
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.75rem;
}

/*Logo size adjustments*/
.nextensio__navbar-links_logo img{
    width: 200px;
    height: 44px;
}

/*Display spacing for the links-contatiner*/
.nextensio__navbar-links_spacing {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Individual spacing settings for each link container*/
.nextensio__navbar-links_spacing a {
    margin-left: 2rem;
    margin-right: 2rem;
    font-weight: 500;
}

/*Universal display settings for the NavBar link placments*/
.nextensio__navbar-links_container {
    display: flex;
    flex-direction: row;
}

/*Display settings for Contact Us*/
.nextensio__navbar-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/*Dispaly settings for the Login-IN ICON*/
.nextensio__navbar-contact a{
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Customizations for the Login-IN ICON*/
.nextensio__navbar-contact svg {
    margin-right: 0.75rem;
    color: var(--color-icon);
}

/*Hover color-change animation for button*/
.nextensio__navbar-links_container a:hover, /*Full-screen Menu buttons*/
.nextensio__navbar-contact svg:hover, /*Login Icon*/
.nextensio__navbar-menu svg:hover, /*3-Dash Icon*/
.nextensio__navbar-menu_container button:hover, /*Side Menu-bar buttons*/
.nextensio__navbar-menu_container svg:hover /*Side Menu-bar icons*/
{
    color: var(--color-secondary);
}

/*Menu bar TEXT customizations*/
.nextensio__navbar-links_container p,
.nextensio__navbar-contact p,
.nextensio__navbar-menu_container p {
    color: var(--color-tertiary);
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 25px;

    cursor: pointer;
}

/*NavBar Button customizations*/
.nextensio__navbar-contact button,
.nextensio__navbar-menu_container button{
    /*Box fillet + spacing inside of the button*/
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    border: 0;
    outline: none;

    background: var(--gradient-text);
    font-family: var(--font-family);
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.04em;

    cursor: pointer;
}

/*Hover transform animation for button*/
.nextensio__navbar-contact button:hover {
    transform: scale(0.99);
    transition-duration: 0.1s;
}

/*Display settings for the menu-bar to have a dimmed background*/
.nextensio__navbar-menu_container-dimmed {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background: rgba(0,0,0,0.85);
    backdrop-filter: blur(7px);
    z-index: 20;

    cursor: pointer;
}

/*MENU icon display settings*/
.nextensio__navbar-menu {
    display: none;
    margin-left: 1rem;
    position: relative;
}

/*MENU icon display settings*/
.nextensio__navbar-menu svg {
    cursor: pointer;
    color: var(--color-icon);
}

/*Popup MENU => skeleton layout*/
.nextensio__navbar-menu_container {
    /*display settings*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 10000;

    /*customizations*/
    background: var(--color-ribbon);
    /* [Top, Right, Bottom, Left] */
    padding: 28px 24px 0px 24px;
    position: fixed;
    top: 0px;
    right: 0px;
    height: 100%;

    cursor: auto;
}

/*DARK/LIGHT Mode toggler settings*/
.nextensio__container-icons svg{
    color: unset;
    color: var(--color-icon);
}

/*Display settings for Exit ICON*/
.nextensio__navbar-menu_toggler{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.50rem;
    margin-bottom: 1.5rem;
}

/*Display settings for the menu container CLOSE icon*/
.nextensio__navbar-menu_toggler svg {
    cursor: pointer;
}

/*Display settings for container-links (TEXT + ARROW)*/
.nextensio__navbar-menu_container-links a{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

/*Display customizations for the menu-bar ARROW icon*/
.nextensio__navbar-menu_container-links svg {
    color: var(--color-tertiary);
}

/*DARK mode settings*/
#dark .nextensio__navbar-menu_container-links svg {
    color: var(--color-tertiary);
}

/*Popup MENU display settings*/
.nextensio__navbar-menu_container button {
    display: flex;
    flex-direction: row;
    text-align: left;

    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
    padding: 1rem 0rem;
    width: 272px;
    border: 1px transparent;
    outline: none;
    background:transparent;
    color: var(--color-tertiary);
}

/*Gradient bar customizations*/
.nextensio__navbar-menu_gradient-bar {
    justify-content: center;
    width: 100%;
    height: 2px;
    margin: 1rem 0rem 1rem 0rem;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
    background: var(--gradient-bar);
}

/*Display settings for the second gradient bar*/
.nextensio__navbar-menu_gradient-bar:nth-last-of-type(2){
    display: none;
}

/*Display settings for Sign-In/Contact links (TEXT + ARROW)*/
.nextensio__navbar-menu_container-links-contact a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/*Customizations for Sign-In ICON*/
.nextensio__navbar-menu_container-links-contact svg {
    color: var(--color-tertiary);
}

/*Customizations for Sign-In TEXT*/
.nextensio__navbar-menu_container-links-contact button {
    margin-left: 8px;
    width: 242px;
    font-size: 20px;
}

/*Popup MENU display settings*/
.nextensio__navbar-menu_container-links-contact {
    display: none;
}

/*Widescreen formatting*/
@media screen and (min-width: 1920px){
    .nextensio__navbar {
        padding: 1rem 18rem;
    }
}

/*Computer formatting*/
@media screen and (min-width: 1480px) {
    .nextensio__navbar-links_logo img{
        transform: scale(1.05);
    }
    
    .nextensio__navbar-links_container p {
        font-size: 20px;
    }
}

/*Computer formatting*/
@media screen and (max-width: 1205px){
    /*Horizontal weblinks*/
    .nextensio__navbar-links_container {
        display: none;
    }

    /*Side menu + ICON*/
    .nextensio__navbar-menu {
        display: flex;
    }

    /*Screen-dimmer*/
    .nextensio__navbar-menu_container-dimmed {
        display: block;
    }
}

/*Tablet formatting*/
@media screen and (max-width: 800px){
    .nextensio__navbar {
        padding: 1rem 3rem;
    }
}

/*Mobile formatting #2*/
@media screen and (max-width: 650px){
    .nextensio__navbar-contact {
        display: none;
    }

    .nextensio__navbar-menu_container-links-contact {
        display: block;
    }

    .nextensio__navbar-menu_gradient-bar:nth-last-of-type(2) {
        display: flex;
    }
}

/*Mobile formatting*/
@media screen and (max-width: 550px){
    .nextensio__navbar {
        padding: 1rem 2rem;
    }
}